/* tslint:disable:no-var-requires */
require('./index.scss');

// import { attachTo} from './components/component';
// import { GoogleContactForm } from './components/contact-form/contact-form';

(() => {
	window.addEventListener('DOMContentLoaded', (e: Event) => {
		// attach to the forms
		// attachTo('.js-google-contact-form', GoogleContactForm);
	});
})();

